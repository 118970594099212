import React, {useState, useEffect, Suspense, lazy} from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import {MapPinIcon} from "@heroicons/react/20/solid";
import Loading from "./Loading";
import LoadingFull from './LoadingFull';

interface ShopBoxProps {
    nom: string;
    images: { src: string; size: string }[];
    link: string;
    localisation: string;
    localisationlink: string;
}

export default function ShopBox({ nom, images, link, localisation, localisationlink }: ShopBoxProps) {
    const [backgroundImage, setBackgroundImage] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        function handlePageShow(event: { persisted: any; }) {
            if (event.persisted) {
                setLoading(false);
            }
        }

        window.addEventListener("pageshow", handlePageShow);

        return () => {
            window.removeEventListener("pageshow", handlePageShow);
        };
    }, []);
    const getBackgroundImage = () => {
        const width = window.innerWidth;
        const newimages = images.filter(image => image.size.includes(String(width)));

        const lastImage = newimages.length > 0 ? newimages[newimages.length - 1].src : images[images.length - 1].src;

        setBackgroundImage(lastImage);
    };

    useEffect(() => {
        getBackgroundImage();
    }, []);

    return (
        <>
            {loading &&
            <Suspense fallback={<Loading/>}>
            <LoadingFull/>
            </Suspense>
            }
        <div className="w-72 md:w-96 shadow-xl bg-white relative overflow-hidden rounded-xl">
            <div className="py-4">
                <h3 className="text-md lg:text-2xl text-center uppercase text-ozlaloc-200">{nom}</h3>
                <h4 className="text-xs lg:text-lg text-ozlaloc-200 "><a className={'flex hover:underline items-center justify-center'} target={'_blank'} rel={'follow'} href={localisationlink}><MapPinIcon className={' h-4 w-4'}/>{localisation}</a></h4>
            </div>

            <a href={link} aria-label={`Se rendre sur la boutique de Ozlaloc ${nom}`} onClick={() => setLoading(true)} className="w-72 md:w-96 bg-cover bg-no-repeat relative">
                <div
                    className="w-72 md:w-96 bg-cover bg-no-repeat relative duration-500"
                    style={{
                        backgroundImage: `url(${backgroundImage})`,
                    }}
                    onMouseEnter={(e) => {
                        e.currentTarget.style.opacity = String(0.65);
                    }}
                    onMouseLeave={(e) => {
                        e.currentTarget.style.opacity = String(1);
                    }}
                >
                    <div
                        className="w-72 md:w-96 bg-cover bg-no-repeat relative duration-500"
                        onMouseEnter={(e) => {
                            e.currentTarget.style.transform = 'scale(1.3)';
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.transform = 'scale(1)';
                        }}
                    >
                        <p className="py-28 text-center">
                            <ChevronDownIcon
                                className="text-white bg-ozlaloc-300 rounded-full w-8 h-8 px-2 py-2 mx-auto"
                                style={{
                                    transition: 'transform 0.3s ease-in-out',
                                    transform: 'scale(1)',
                                }}
                            />
                        </p>
                    </div>
                </div>
            </a>
        </div>
        </>
    );
}
