import React from 'react';

export default function LoadingFull() {
    return (
        <div className="fixed inset-0 items-center flex  justify-center bg-white bg-opacity-70 z-50">
            <div className={'my-auto'}>
            <div className="loader">
                <img
                    src="https://assets.ozlaloc.fr/logo/logo_min_transparent.webp"
                    className="h-44 w-44 mx-auto"
                    alt="Logo"
                />
            </div>
            <p className="text-2xl text-center mt-4">Chargement en cours...</p>
            </div>
        </div>

    );
}
