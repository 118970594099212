import React, {Suspense, lazy} from 'react';
import './App.css';
import "./assets/App.css";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Loading from "./scripts/components/elements/Loading";
import ErrorBoundary from "./scripts/components/elements/ErrorBoundary";
import {HelmetProvider} from "react-helmet-async";
import Main from "./scripts/components/Main";

const LazyWebSiteRouter = lazy(() => import('./scripts/routers/WebSiteRouter'));
const LazyWebSiteUserRouter = lazy(() => import('./scripts/routers/UserRouter'));



export interface RouteElement {
    path: string;
    element: JSX.Element;
}


function App() {

    return (
        <ErrorBoundary>
            <HelmetProvider>
    <BrowserRouter>


                <Routes>

                    <Route path="/" element={<Main />} />



                    <Route
                        path="/mon-compte/*"
                        element={<Suspense fallback={<Loading />}>
                            <LazyWebSiteUserRouter />
                        </Suspense>}
                    />

                    <Route
                        path="/*"
                        element={  <Suspense fallback={<Loading />}>
                            <LazyWebSiteRouter />
                        </Suspense>}
                    />
                </Routes>
    </BrowserRouter>
        </HelmetProvider>
        </ErrorBoundary>

);
}

export default App;
