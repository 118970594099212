import React from 'react';
import ShopBox from "../elements/ShopBox";


export default function BookOnlineContainer() {
    return (
        <section className={'mt-24 lg:mt-16 text-center mx-auto'}>
            <h2 className={'text-xl lg:text-3xl font-extrabold uppercase mt-4'}>Réservez en ligne</h2>
            <h3 className="text-xs font-semibold text-center mx-2 opacity-75 mb-4">+ de 600 matériels en location </h3>
            <div className="flex items-center justify-center mt-2">
                <section className="flex flex-col lg:flex-row gap-x-8 gap-y-4">
                    <ShopBox nom="Nouvoitou" localisation={"Rue Gustave Eiffel"} images={[
                        { src: 'https://assets.ozlaloc.fr/boutique/vern/vern_200x158.webp', size: '200w' },
                        { src: 'https://assets.ozlaloc.fr/boutique/vern/vern_505x505.webp', size: '505w' },
                        { src: 'https://assets.ozlaloc.fr/boutique/vern/vern_638x505.webp', size: '638w' },
                        { src: 'https://assets.ozlaloc.fr/boutique/vern/vern_764x605.webp', size: '764w' },
                        { src: 'https://assets.ozlaloc.fr/boutique/vern/vern_873x691.webp', size: '873w' },
                        { src: 'https://assets.ozlaloc.fr/boutique/vern/vern_964x764.webp', size: '964w' },
                        { src: 'https://assets.ozlaloc.fr/boutique/vern/vern_1105x875.webp', size: '1105w' },
                        { src: 'https://assets.ozlaloc.fr/boutique/vern/vern_1395x1105.webp', size: '1395w' },
                        { src: 'https://assets.ozlaloc.fr/boutique/vern/vern_1400x1109.webp', size: '1400w' },
                    ]
                    }
                             localisationlink={'https://www.google.com/maps/place/OZLALOC+Nouvoitou'}

                             link={'https://ozlaloc.fr/magasins/Nouvoitou'}/>
                    <ShopBox nom="Liffré" localisation={'Centre cial Intermarché'} images={  [
                        { src: 'https://assets.ozlaloc.fr/boutique/liffre/liffre_200x185.webp', size: '200w' },
                        { src: 'https://assets.ozlaloc.fr/boutique/liffre/liffre_449x449.webp', size: '449w' },
                        { src: 'https://assets.ozlaloc.fr/boutique/liffre/liffre_627x627.webp', size: '627w' },
                        { src: 'https://assets.ozlaloc.fr/boutique/liffre/liffre_820x820.webp', size: '820w' },
                        { src: 'https://assets.ozlaloc.fr/boutique/liffre/liffre_986x986.webp', size: '986w' },
                        { src: 'https://assets.ozlaloc.fr/boutique/liffre/liffre_1162x1162.webp', size: '1162w' },
                        { src: 'https://assets.ozlaloc.fr/boutique/liffre/liffre_1338x1338.webp', size: '1338w' },
                        { src: 'https://assets.ozlaloc.fr/boutique/liffre/liffre_1377x1377.webp', size: '1377w' },
                        { src: 'https://assets.ozlaloc.fr/boutique/liffre/liffre_1400x1400.webp', size: '1400w' },
                    ]}
                             localisationlink={'https://www.google.com/maps/place/@48.2255605,-1.5005881,19z/data=!4m6!3m5!1s0x480ed14dc11c4875:0x5b1ec00f713eda44!8m2!3d48.2254953!4d-1.5000329!16s%2Fg%2F11nmlmw0df?entry=ttu'}

                             link={'https://ozlaloc.fr/magasins/Liffre/'}/>

                </section>
            </div>
        </section>

    )
}