import { Dialog, Menu, Transition } from '@headlessui/react';
import React, {Fragment, lazy, Suspense, useEffect, useState} from 'react';
import {LazyLoadImage} from "react-lazy-load-image-component";
import {XMarkIcon} from "@heroicons/react/20/solid";
import Loading from "./Loading";
import ReactHtmlParser from 'react-html-parser';

const LoadingFull = lazy(() => import('./LoadingFull'));

interface CatalogueIconInterface {
    name: string;
    img: string;
    url: string;
    desc: string;
    linkid: string;
    fulldesc: string;
    desc2: string;
    descmobile1: string;
    descmobile2: string;
    imgpresentation: string;
}
export default function CatalogueIcon(values: CatalogueIconInterface) {
    const [open, setOpen] = useState(false)

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        function handlePageShow(event: { persisted: any; }) {
            if (event.persisted) {
                setLoading(false);
            }
        }

        window.addEventListener("pageshow", handlePageShow);

        return () => {
            window.removeEventListener("pageshow", handlePageShow);
        };
    }, []);
    return (
        <>
            {loading &&
            <Suspense fallback={<Loading/>}>
            <LoadingFull/>
</Suspense>
            }
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <div className="fixed inset-0" />

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                        <div className={`flex h-full flex-col overflow-y-scroll ${values.linkid === 'evenementiel' ? 'bg-black' : values.linkid === 'bricolage' ? 'bg-amber-800' : 'bg-green-600'} shadow-xl`}>
                                            <div className="px-4 py-6 sm:px-6">
                                                <div className="flex items-start justify-between">
                                                    <h2 id="slide-over-heading" className="flex text-base font-semibold leading-6 text-gray-100 gap-x-2">
                                                        <LazyLoadImage alt={values.name} className=" brightness-0-invert-100 h-6 w-6 " src={values.img} />

                                                        {values.name.toUpperCase()}
                                                    </h2>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                                                            onClick={() => setOpen(false)}
                                                        >
                                                            <span className="absolute -inset-2.5" />
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Main */}
                                            <div>
                                                <div className="pb-1 sm:pb-6">
                                                    <div>
                                                        <div className="relative h-40 sm:h-56">
                                                            <img
                                                                className="absolute h-full w-full object-cover"
                                                                src={values.imgpresentation}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="mt-6 px-4 sm:mt-2 sm:flex sm:items-end sm:px-6">
                                                            <div className="sm:flex-1">

                                                                <div className="mt-5 flex flex-wrap sm:space-x-3 space-y-0">
                                                                    <Menu as="div" className="relative inline-block text-left flex-grow">
                                                                        <div className=" mt-auto flex-grow mb-2">
                                                                            <Menu.Button className="w-full inline-flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 whitespace-nowrap focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                                                                Réserver dès maintenant
                                                                            </Menu.Button>
                                                                        </div>

                                                                        <Transition
                                                                            as={Fragment}
                                                                            enter="transition ease-out duration-100"
                                                                            enterFrom="transform opacity-0 scale-95"
                                                                            enterTo="transform opacity-100 scale-100"
                                                                            leave="transition ease-in duration-75"
                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                            leaveTo="transform opacity-0 scale-95"
                                                                        >
                                                                            <Menu.Items className="absolute  z-10 mt-2 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                <div className="py-1">
                                                                                    <Menu.Item>
                                                                                        <a className={'block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900'}
                                                                                           rel={'follow'}
                                                                                           onClick={() => setLoading(true)}
                                                                                        href={`https://ozlaloc.fr/magasins/Liffre/categorie/${values.linkid.toLowerCase()}`}>
                                                                                            Liffré
                                                                                        </a>
                                                                                    </Menu.Item>
                                                                                    <Menu.Item>
                                                                                        <a className={'block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900'}
                                                                                           rel={'follow'}
                                                                                           onClick={() => setLoading(true)}
                                                                                           href={`https://ozlaloc.fr/magasins/Nouvoitou/categorie/${values.linkid.toLowerCase() === 'evenementiel' ? 'evenement' : values.linkid.toLowerCase()}`}>
                                                                                            Nouvoitou
                                                                                        </a>
                                                                                    </Menu.Item>
                                                                                </div>

                                                                            </Menu.Items>
                                                                        </Transition>
                                                                    </Menu>

                                                                    <a
                                                                        href={values.url}
                                                                        rel={'follow'}
                                                                        target={'_blank'}
                                                                        className="w-auto h-full inline-flex items-center justify-center rounded-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                                    >
                                                                        Voir le Catalogue
                                                                    </a>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="px-4 pb-5 pt-5 sm:px-0 sm:pt-0">
                                                    <dl className="space-y-8 px-4 sm:space-y-6 sm:px-6">
                                                        <div>
                                                            <p className="mt-1 text-sm text-white sm:col-span-2">
                                                                <p>
                                                                   {ReactHtmlParser(values.fulldesc)}
                                                                </p>
                                                            </p>
                                                        </div>

                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
                <button
                    style={{ color: '#FFFFFF' }}
                    className={`w-full mx-auto hover:scale-110 duration-500 text-center items-center mt-2`}
                    onClick={() => setOpen(!open)}
                >
                <div className={'flex lg:block item-center justify-center'}>
                    <div className="w-20 lg:mx-auto">
                        <LazyLoadImage title={values.name} alt={values.name} className="mx-auto brightness-0-invert-100 h-14 w-14 lg:w-28 lg:h-28" src={values.img} />
                    </div>
                    <h3 className="uppercase text-md my-auto lg:my-0 lg:text-xl lg:mx-2">{values.name[0].toUpperCase() + values.name.slice(1, values.name.length).toLowerCase()}</h3>
                </div>
                <div>
                   <h4 className="hidden md:block lg:text-xs text-[0.80rem] opacity-60">{values.desc}<br/>{values.desc2}</h4>
                   <h4 className="md:hidden block lg:text-xs text-[0.80rem] opacity-60">{values.descmobile1}<br/>{values.descmobile2}</h4>

               </div>
                </button>


        </>





    )
}