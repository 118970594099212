import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import ReactGA from 'react-ga4';
import App from './App';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const Main = () => {
    useEffect(() => {
        if (getCookieConsentValue() === 'true') {
            // Initialiser Google Analytics si le consentement est donné
            ReactGA.initialize('UA-137498769-1');
            ReactGA.send("pageview");
        }
    }, []);

    const handleAcceptCookies = () => {
        // Initialiser Google Analytics après acceptation des cookies
        ReactGA.initialize('UA-137498769-1');
        ReactGA.send("pageview");
    };

    return (
        <React.StrictMode>
            <App />
            <CookieConsent
                onAccept={handleAcceptCookies}
                buttonText="J'accepte"
                enableDeclineButton
                declineButtonText="Je refuse"

            >
                Ce site utilise des cookies pour améliorer l'expérience utilisateur.
            </CookieConsent>
        </React.StrictMode>
    );
};

root.render(<Main />);

